#Endpoint {
  .endpoint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .inner-page-container {
      width: 100%;

      & > div {
        padding: 0 15px;
      }
    }

    &-close-button {
      position: absolute;
      right: 4px;
      top: 120px;
      cursor: pointer;
    }

    &-flag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 72px;
      margin-bottom: 24px;
    }

    .detail-tile-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-detail-tile {
      height: 51px;
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (max-width: 576px) {
        flex-basis: 100%;
      }

      span {
        font-size: 14px;
        font-weight: 400;
      }

      label {
        left: 6px;
      }
    }

    &-title-tile {
      display: flex;
      flex-direction: row;
    }

    &-content-tile {
      display: flex;
      flex-direction: row;
      justify-self: flex-end;
      right: 0;
      padding: 3px 10px;

      &-url {
        display: flex;
        flex-direction: row;

        svg {
          margin-left: 16px;
          margin-top: 3px;
        }
      }
    }
  }
}
